<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <CasesHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p>我今年46岁，开了一家国际交流机构，经常与海外各地学校、组织、公司等进行合作举办各类活动，促进人才交流。多年工作以来，我逐渐熟悉了海外的市场与教育环境，尤其对美国情有独钟，对其未来的发展空间很有信心。在与家人沟通后一致同意，办理美国移民将有助于扩大我的生意，并且孩子也能享受更多的前途发展选择。</p>
        <p>决定办理移民后我便开始了搜寻中介的漫漫长途，先后参加了多家移民中介办理的活动，终在跃迁出国公司的一场推荐会上找到了心仪的项目。我明白办理EB-5类别的美国移民至关重要的一点就是选择可靠的项目，才能保证资金安全和就业岗位。在推荐会上，来自美国项目方和律师行的负责人都出席做了项目和移民方面的介绍，我了解到跃迁公司不但拥有100%成功率的傲人成就，其合作的美国律师团队也资历深厚，甚至包括了前移民官，专业背景相当厉害。会后我用英语直接与项目方的负责人和律师做了短暂交流，从而解惑了一些移民程序和要求方面的问题。之后在现场，跃迁公司的顾问为我提供了单独咨询，就我和家人的情况进行了专业的分析与讨论，当场敲定了用企业借款的方式来解释50万美金的合法来源。当时在会场整个过程感受到了高度的专业性和热忱的服务，于是现场就拍板签订了委托协议并启动了投资和文件准备。在我的文档经理的指导下，很快准备齐全了资金证明文件，之后在文档经理的培训下，完成了体检和面试文件。</p>
        <p>正如初我决定签约的时候所认定的，在办理移民的两年多时间里，从投资、准备文件到获批后的培训和包括税务、购房、保险等等方面的安居服务，我享受到了专业高效的一条龙服务，感谢跃迁让我的移民之路如此顺利！</p>
        <p>
          <img loading="lazy" class="aligncenter" src="@/assets/Cases/9/1.jpg">
        </p>

      </div>

      <CasesFooter class="mt-5" :index="info.index"/>
    </div>
  </div>

</template>

<script>
import CasesHeader from "@/components/Cases/CasesHeader";
import CasesFooter from "@/components/Cases/CasesFooter";

export default {
  name: "Cases11",
  components: {CasesFooter, CasesHeader},
  data() {
    return {
      info: this.$route.meta,
    }
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>